<template>
    <nav class="bg-[--nav-bg-color] text-white">
      <div class="flex justify-between items-center pr-3 pl-2 h-[70px] border-b-[--line-color]">
        <!-- 导航栏标题 -->
        <a href="/" class="text-2xl font-semibold w-[200px] p-3">中医学习助手</a>

        <!-- PC端的菜单栏 -->
        <div class="ml-6 w-full flex-wrap hidden lg:flex items-center justify-between h-[70px] pr-4 pl-4">
            <div class="flex-1 flex gap-6 flex-wrap items-center">
                <router-link @click.native="isNavbarOpen=false" v-for="item in navList" :key="item.toName" :to="{name: item.toName}">{{ item.title }}</router-link> 
                <div class="game-nav flex flex-col">
                  <a>助记游戏</a>
                  <div class="child">
                    <router-link @click.native="isNavbarOpen=false" v-for="item in gameList" :key="item.toName" :to="{name: item.toName}">{{ item.title }}</router-link> 
                  </div>
                </div>
            </div>
            <div class="">
                <router-link @click.native="isNavbarOpen=false" v-for="item in rightNavList" :key="item.toName" :to="{name: item.toName}">{{ item.title }}</router-link> 
            </div>
        </div>
        <!-- 导航栏按钮 -->
        <div class="lg:hidden" @click="toggleNavbar">
          <i class="el-icon-menu text-4xl p-2"></i>
        </div>
      </div>

      <!-- 移动端的菜单栏 -->
      <div class="absolute m-nav
        left-0 right-0 top-[70px] z-30 bg-[--nav-bg-color] 
        w-full flex gap-4 flex-col p-8" v-if="isNavbarOpen">
            <router-link @click.native="isNavbarOpen=false" v-for="item in navList" :key="item.toName" :to="{name: item.toName}">{{ item.title }}</router-link> 

            <div class="mt-8"></div>

            <div>助记游戏</div>
            <router-link @click.native="isNavbarOpen=false" v-for="item in gameList" :key="item.toName" :to="{name: item.toName}">{{ item.title }}</router-link> 
  
            <div class="mt-8"></div>

            <router-link @click.native="isNavbarOpen=false" v-for="item in rightNavList" :key="item.toName" :to="{name: item.toName}">{{ item.title }}</router-link> 
    </div>

    </nav>
  </template>
  
  <script>
  export default {
    name: "TopNav",
    data() {
      return {
        isNavbarOpen: false, // 导航栏展开状态

        navList: [
            {
                toName: "Document",
                title: "使用文档"
            },
            {
                toName: "ZhenDuan",
                title: "诊断依据"
            },
            {
                toName: "MBXR",
                title: "模板渲染"
            },
            {
                toName: "RCSC",
                title: "日常生成"
            },
            
        ],

        gameList: [
          {
              toName: "YaoDui",
              title: "中药歌括"
          },
          {
              toName: "BenCao_SJYXZ",
              title: "本草诗解"
          }
        ],

        rightNavList: [
            {
                toName: "Admin",
                title: "管理数据"
            },
        ]
      };
    },
    methods: {
      // 切换导航栏展开状态
      toggleNavbar() {
        this.isNavbarOpen = !this.isNavbarOpen;
      }
    }
  };
  </script>
  
<style lang="less" scoped>
nav {
  a {
    font-weight: bold;
    color: white;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.m-nav{
    a{
        height: 4em;
        border-bottom: 1px solid #efefef;
        display: flex;
        align-items: center;
        &:hover{
            background-color: #efefef;
            color: var(--nav-bg-color) !important;
            padding-left: 1em;
        }
    }
}

.game-nav{
  position: relative;
  // background-color: #42b983;
  height: 70px;
  line-height: 70px;
  // padding: 0 1em;
  .child{
    display: none;
    position: absolute;
    left: 0;
    top: 70px;
    background-color: var(--nav-bg-color);
    width: 9em;
    flex-direction: column;
    a{
      padding-left: 1em;
      &:hover{
        background-color: #42b983;
        color: var(--nav-bg-color);
      }
    }
    &:hover{
      display: flex;
    }
  }
  &:hover{
    .child{
      display: flex;
    }
  }
}
</style>