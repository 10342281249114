


// 检测用户是否在移动设备上打开页面
export function CheckIsMobile(){
    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobile) {
      return true;
    }
    return false;
}