import Vue from 'vue'
import VueRouter from 'vue-router'
import { IsLoginFunc } from '../utils/auth.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/zhenduan', //默认打开诊断
  },
  {
    path: '/docs',
    name: 'Document',
    component: () => import(/* webpackChunkName: "home" */ '../views/DocumentView.vue')
  },
  {
    path: '/game/yaodui',
    name: 'YaoDui',
    component: () => import(/* webpackChunkName: "yaodui" */ '../views/Game/YaoDui.vue')
  },
  {
    path: '/game/bencao',
    name: 'BenCao_SJYXZ',
    component: () => import(/* webpackChunkName: "bencao" */ '../views/Game/BenCao_SJYXZ.vue')
  },
  {
    path: '/mbxr',
    name: 'MBXR',
    component: () => import(/* webpackChunkName: "mbxr" */ '../views/MBXRView.vue')
  },
  {
    path: '/zhenduan',
    name: 'ZhenDuan',
    component: () => import('../views/ZhenDuanView.vue')
  },
  {
    path: '/rcsc',
    name: 'RCSC',
    component: () => import('../views/RCSCView.vue')
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin/AdminLayout.vue'),
    redirect: '/admin/weclome',
    meta: { isAdmin: true },
    //子路由
    children: [
      {
        path: 'weclome',
        name: 'AdminWeclome',
        meta: { isAdmin: true },
        component: () => import('../views/Admin/AdminWeclome.vue')
      },
      {
        path: 'zhenduan',
        name: 'AdminZhenDuan',
        meta: { isAdmin: true },
        component: () => import('../views/Admin/AdminZhenDuan.vue')
      },
      {
        path: 'option',
        name: 'AdminOption',
        meta: { isAdmin: true },
        component: () => import('../views/Admin/AdminOption.vue')
      },
      {
        path: 'rcsc',
        name: 'AdminRCSC',
        meta: { isAdmin: true },
        component: () => import('../views/Admin/AdminRCSC.vue')
      },
      {
        path: 'document',
        name: 'AdminDocument',
        meta: { isAdmin: true },
        component: () => import('../views/Admin/AdminDocument.vue')
      },
    ],
  }
]

const router = new VueRouter({
  routes
})

// 不管什么路由切换时都会执行该方法
router.beforeEach((to, from, next) => {
  // to 表示到哪去
  // from 表示从哪来
  // 登陆时向localStorage存一下登录状态
  let isLogin = IsLoginFunc();
  // 如果已登录 或 当前就在登录页 可以执行下一步, 否则 跳去登录页
  if(to.meta && to.meta.isAdmin){
    console.log("isLogin:",isLogin)
    if(!isLogin){
      next({ name: 'Login' })
    }else{
      next()
    }
  }else{
    next()
  }
})



export default router
