import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/style/tailwind.less';

import ElementUI from 'element-ui';
//自定义了一些主题颜色
import './assets/style/element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css';

//自己的css样式
import './assets/style/app.less';

// 富文本
// import '@wangeditor/editor/dist/css/style.css'


import axios from '@/utils/http.js'
Vue.prototype.$axios = axios

Vue.use(ElementUI);
Vue.config.productionTip = false

// 全局过滤器
Vue.filter('truncateText', function(value, maxLength) {
  if (!value) return '';
  if (value.length <= maxLength) return value;
  // 截取字符串前 maxLength 个字符，并添加省略号
  return value.substring(0, maxLength) + '...';
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
