<template>
  <div id="app">
    <!-- <div class="h-[30px] w-full bg-[#d1314b] text-[#ffffff] absolute top-0 left-0  flex items-center justify-center" v-if="false">
      * 所有输出的内容仅供参考，<strong>不保证其准确性</strong>，采用时请认真校验
    </div>
    <div class="mt-[30px]" v-if="false"></div>
    <nav class="h-[70px] bg-[--nav-bg-color] flex p-6 items-center justify-between 
                border-b border-b-[--line-color]">
      <div class="flex flex-row items-center gap-6">
        <div class="logo text-white text-3xl">
          <a href="/" class="text-white">
            病历助手
          </a>
        </div>
        <div class="flex gap-3 ml-8">
          <router-link :to="{name:'Document'}">使用文档</router-link> | 
          <router-link :to="{name:'ZhenDuan'}">诊断依据</router-link> | 
          <router-link :to="{name:'MBXR'}">模板渲染</router-link> | 
          <router-link :to="{name:'RCSC'}">日常生成</router-link> | 
          <router-link :to="{name:'YaoDui'}">中药歌括</router-link>
        </div>
      </div>
      <div class="">
        <router-link to="/admin">管理数据</router-link> 
      </div>
    </nav> -->

    <TopNav class="h-[70px]"></TopNav>

    <router-view/>

    <!-- <div class="fixed w-lvw h-lvh bg-[#455] text-white left-0 top-0 z-[999]" v-if="isMobile">
      <div class="flex flex-col w-full h-full items-center justify-center text-2xl leading-[2em]">
        <p class="font-semibold mb-4 text-red-200">【友好】提示：</p>
        <p>不支持移动设备使用</p>
        <p>请用 PC 电脑端打开</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { CheckIsMobile } from '@/utils/check_.js'
import TopNav from "@/components/TopNav.vue"

export default({
  name: 'App',
  components: {
    TopNav
  },
  data(){
    return {
      isMobile: false,
    }
  },
  mounted(){
    //判断是不是移动设备
    this.isMobile = CheckIsMobile();
    console.log("检查是否是移动设备：",this.isMobile);
  }
})
</script>


<style lang="less" scoped>
nav {
  a {
    font-weight: bold;
    color: white;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>


