// 验证是否登陆
export function IsLoginFunc(){
    let token = GetToken()
    console.log("IsLoginFunc：",token)
    if(token==""||token==null||token==undefined){
        return false
    }
    return true
}
// 退出登陆
export function LogOutFunc(){
    DeleteToken()
}

// 保存token
export function SaveToken(token){
    sessionStorage.setItem("token",token)
}
// 获取token
export function GetToken(){
    return sessionStorage.getItem("token")
}
// 删除token
export function DeleteToken(){
    return sessionStorage.removeItem("token")
}
